import { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";


const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const LoginForm = ({ handleLogin, loading, message }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const inputRefUsername = useRef(null);
    const inputRefPassword = useRef(null);

    return (
        <Form onSubmit={handleLogin}>
            <div className="form-group">
                <label htmlFor="username">Username</label>
                <TextBoxComponent
                    ref={inputRefUsername}
                    className="form-control"
                    name="username"
                    onClick={() => {
                        inputRefUsername.current?.element.focus()
                    }}
                    change={(e) => {
                        setUsername(e.value)
                    }}
                    value={username}
                />
            </div>

            <div className="form-group">
                <label htmlFor="password">Password</label>
                <TextBoxComponent
                    ref={inputRefPassword}
                    className="form-control"
                    name="password"
                    type="password"
                    onClick={() => {
                        inputRefPassword.current?.element.focus()
                    }}
                    change={(e) => {
                        setPassword(e.value)
                    }}
                    value={password}
                />
            </div>

            <div className="form-group">
                <button
                    style={{ marginTop: "10px", marginLeft: "100px" }}
                    className="btn btn-primary btn-block"
                    disabled={loading}
                >
                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                    <span>Login</span>
                </button>
            </div>

            {message && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                </div>
            )}

            <CheckButton style={{ display: "none" }} />
        </Form>
    );
};

export default LoginForm;