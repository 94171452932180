import React, { useRef, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
import 'leaflet.markercluster';

const Map = ({ panel, controls, animalsData }) => {
    const mapRef = useRef(null);
    const markersRef = useRef([]);

    const initializeMap = () => {
        if (!mapRef.current) {
            mapRef.current = L.map('map', {
                center: [panel.panel_json.map_definition.centroid.coordinates[1], panel.panel_json.map_definition.centroid.coordinates[0]] || [-105.004311, 39.748359],
                zoom: panel.panel_json.map_definition.zoom || 13,
            });

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors'
            }).addTo(mapRef.current);
            L.markerClusterGroup().addTo(mapRef.current);
        }
    };


    const getPopupContent = (animal) => {
        let popupContent = '';
        // Iterate over the properties of the animal object
        for (const [key, value] of Object.entries(animal)) {
            if (value) {
                popupContent += `<b>${key}:</b> ${value}<br>`;
            }
        }

        return popupContent;
    };

    const addMarkers = () => {
        if (mapRef.current) {
            const markers = L.markerClusterGroup();
            const controlsMapped = controls.map(({ hidden, name, label }) => ({ hidden, name, label }));

            const toMutateAnimalsData = (data) => {
                const mutationAnimalsData = [];

                const extractCoordinates = (item) => {
                    const temp = {};

                    for (const key in item) {
                        if (key === 'type' && item[key] === 'Point' && item.coordinates) {
                            temp.coordinates = item.coordinates;
                        } else if (typeof item[key] === 'object') {
                            const nestedCoordinates = extractCoordinates(item[key]);
                            if (nestedCoordinates) {
                                temp.coordinates = nestedCoordinates;
                            }
                        }
                    }

                    return temp.coordinates;
                };

                animalsData && animalsData.forEach((item = {}) => {
                    const temp = {};
                    const coordinates = extractCoordinates(item);

                    if (coordinates) {
                        temp.coordinates = coordinates;
                        mutationAnimalsData.push(temp);
                    }

                    for (let i = 0; i < controlsMapped.length; i++) {
                        const animal = controlsMapped[i];
                        if (!animal.hidden) {
                            const label = animal.label;
                            temp[label] = item[animal.name];
                        }
                    }
                });

                return mutationAnimalsData;
            };

            const mutationAnimalsData = toMutateAnimalsData();

            mutationAnimalsData.forEach((animal) => {
                const coordinates = animal.coordinates;

                if (coordinates) {
                    const markerIcon = L.icon({
                        iconUrl: require('../../../../assets/map-pin-icon.svg'),
                        iconSize: [25, 41],
                        markerColor: 'yellow'
                    });
                    const marker = L.marker([coordinates[1], coordinates[0]], { icon: markerIcon }).addTo(mapRef.current).bindPopup(getPopupContent(animal));
                    markers.addLayer(marker);
                }
            });
        }
    };

    useEffect(() => {
        initializeMap();
        addMarkers();

        // Clean up markers when the component is unmounted or when mutationAnimalsData changes
        return () => {
            markersRef.current.forEach((marker) => marker.remove());
            markersRef.current = [];
        };
    }, [panel, controls, animalsData]);

    return <div ref={mapRef} style={{ height: '100%' }}></div>;
}

export default Map;