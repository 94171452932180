import React, { useState, useEffect, useRef } from "react";
import { useApolloClient } from '@apollo/react-hooks';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
    CheckBoxComponent,
    RadioButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import { gql } from "@apollo/client";
import moment from 'moment';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import { Player } from '@lottiefiles/react-lottie-player';
import { downloadFileImage } from '../../apollo/mutations';
import noImage from '../../static/img/no-img.png';
import { getLocaleDateString } from '../../utils/getLocaleDateString';

const Input = ({ value, inputQuery, onChange, type, ...rest }) => {
    const inputRef = useRef(null);
    const comboBoxRef = useRef(null);
    const datePickerRef = useRef(null);
    const dateRef = useRef(null);
    const client = useApolloClient();
    const [gridList, setGridList] = useState('');
    const [fields, setFields] = useState({ value: '', text: '', });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cardImage, setCardImage] = useState(null);


    const _getDynamicScreen = async () => {
        setIsLoading(true);
        // setError(null);
        try {
            const { data } = await client.query({ query: gql`${inputQuery}` });
            const queryName = rest.query_name;
            setFields({ value: rest.query_key, text: rest.query_value });
            setGridList(data[queryName]);
        } catch (e) {
            setError(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (inputQuery !== null) {
            _getDynamicScreen()
        }
        return () => {
            setGridList('');
            setFields({ value: '', text: '', });
            setError(null);
        }
    }, [type, inputQuery])

    const localDateFormat = getLocaleDateString()

    switch (type) {
        case "textbox":
            return (
                <TextBoxComponent
                    ref={inputRef}
                    onClick={() => {
                        inputRef.current?.element.focus()
                    }}
                    placeholder={rest?.placeholder}
                    change={({ value }) => {
                        onChange(value)}}
                    value={value}
                />

            );
        case "number":
            return (
                <NumericTextBoxComponent
                    placeholder={rest?.placeholder}
                    change={({ value }) => onChange(value)}
                    value={value}
                />
            );
        case "radio":
            return rest?.options.map((e) => (
                <RadioButtonComponent
                    key={e}
                    label={e}
                    value={e}
                    onChange={(value) => onChange(value)}
                    checked={value === e}
                />
            ));
        case "dropdown":
            const [dropBoxValue, setDropBoxValue] = useState(null);
            if (isLoading || !gridList) {
                return (
                    <Player
                        autoplay
                        loop
                        src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                        style={{ height: '60px', width: '60px' }}
                    />
                );
            }
            if (error) {
                return <div>Error: {error.message}</div>;
            }
            return (
                <ComboBoxComponent
                    ref={comboBoxRef}
                    onClick={() => {
                        comboBoxRef.current?.element.focus();
                    }}
                    dataSource={gridList}
                    fields={fields}
                    allowFiltering={true}
                    filtering={(e) => {
                        if (e.text === '') {
                            e.updateData(gridList);
                        } else {
                            let filteredData = gridList.filter(
                                (item) => item[fields.text].toLowerCase().indexOf(e.text.toLowerCase()) !== -1
                            );
                            e.updateData(filteredData);
                        }
                    }}
                    select={({ itemData }) => {
                        setDropBoxValue(itemData)
                        onChange(itemData[fields.value]);
                    }}
                    change={(e) => {
                        let filteredData = gridList.filter(
                            (item) => item[fields.text].toLowerCase().indexOf(e.itemData?.[fields.text].toLowerCase()) !== -1
                        );
                        if (filteredData.length < 1) {
                            // comboBoxRef.current.element.value = dropBoxValue?.[fields.text];
                            onChange(null);
                        }
                        if (e.itemData === null) {
                            onChange(null);
                        }
                    }}
                    value={value}

                />
            );

        case "checkbox":
            return (
                <CheckBoxComponent
                    label={rest?.checkboxLabel}
                    onChange={(e) => {
                        onChange(e.target.checked)
                    }}
                    checked={value}
                    name={rest?.name}
                />
            );

        case "datetime":

            return (
                <DatePickerComponent
                    ref={datePickerRef}
                    onClick={() => {
                        datePickerRef.current?.element.focus();
                    }}
                    format={localDateFormat}
                    enableMask={true}
                    id="datepicker"
                    change={({ value }) => {
                        // const formattedValue = value ? moment(value).format('DD/MM/YYYY') : null;
                        const currentFormat = localDateFormat.toUpperCase()

                        const formattedValue = value ? moment(value).format(currentFormat) : null;
                        onChange(formattedValue);
                    }}
                    placeholder="Enter date"
                    value={value || null}
                >
                    <Inject services={[MaskedDateTime]} />
                </DatePickerComponent>
            );
        case "date":

            return (
                <DatePickerComponent
                    ref={dateRef}
                    onClick={() => {
                        dateRef.current?.element.focus();
                    }}
                    format={localDateFormat}
                    id="date"
                    change={({ value }) => {
                        onChange(value);
                    }}
                    placeholder="Enter date"
                    value={new Date(value) || null}
                >
                </DatePickerComponent>
            );
        case "geometry":
            return null

        case "image":
            const getAnimalsImage = async (imgId) => {
                const { data } = await client.mutate({ mutation: downloadFileImage, variables: { files_uid: imgId } });
                setCardImage(data.fileDownload.file_content)
            }
            if (value) {
                getAnimalsImage(value)
            }
            return (
                <img
                    style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '5px', marginRight: '16px' }}
                    alt='Image of pet'
                    width='160px'
                    height='150px'
                    src={`data:image/jpeg;base64,${cardImage}`}
                    onError={(e) => {
                        e.target.onerror = null; // prevent infinite loop
                        e.target.src = noImage;
                    }}
                />
            )

        default:
            return null;
    }
};

export default Input;


