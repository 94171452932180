import React, { useState, useEffect, useRef, useContext } from 'react';
import { gql } from "@apollo/client";
import { useNavigate } from 'react-router-dom'
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { PivotViewComponent, Inject, FieldList, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting, NumberFormatting } from '@syncfusion/ej2-react-pivotview';
import { getTaskStatus } from '../../apollo/queries';
import { updatePanelsDefinition } from '../../apollo/mutations';
import { useApolloClient } from '@apollo/react-hooks';
import { SidebarContext } from './Main/context';


const PivotToolbar = ({ panel, panelJSON, pivotsQuery, queryName, dynamicGridHeight }) => {
    const toastRef = React.useRef(null);
    const client = useApolloClient();
    const [dogsVaccinations, setDogsVaccinations] = useState();
    const navigate = useNavigate();
    let pivotObj = useRef(null);
    const [dataSourceSettings, setDataSourceSettings] = useState({});
    const [displayOption, setDisplayOption] = useState({})
    const [chartSettings, setChartSettings] = useState({})
    const [loaded, setLoaded] = useState(false);
    const sidebarContext = useContext(SidebarContext);

    const _getPivotsQuery = async () => {
        const { data } = await client.query({ query: gql`${pivotsQuery}` })
        const responseData = data[queryName]
        setDogsVaccinations(responseData)
        if (panelJSON.pivot_definition === null) {
            setDataSourceSettings({
                dataSource: responseData,
            })
            setDisplayOption({
                view: 'Both',
            })
            setChartSettings({
                title: 'Sample',
            })
            setLoaded(true)
        }
        else {
            let dataSourceSettingsCopy = JSON.parse(JSON.stringify(panelJSON.pivot_definition.pivot.report))
            dataSourceSettingsCopy.dataSourceSettings.dataSource = responseData
            setDataSourceSettings(dataSourceSettingsCopy.dataSourceSettings)
            setDisplayOption(dataSourceSettingsCopy.displayOption)
            setChartSettings(dataSourceSettingsCopy.chartSettings)
            setLoaded(true)
        }

    }



    useEffect(() => {
        _getPivotsQuery()
        return () => {
            // Reset state values to null
            setDogsVaccinations(null)
            setDataSourceSettings(null)
            setDisplayOption(null)
            setChartSettings(null)
            setLoaded(false)

            // Reset pivotObj ref to null
            // pivotObj.current = null
        }
    }, [panel])

    const toolbarOptions = [
        'Save', 'Load',
    'Grid', 'Chart', 'Export', 'SubTotal', 'GrandTotal', 'Formatting', 'FieldList']

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const saveToDB = async (args) => {
        let panelsDefinition = JSON.parse(JSON.stringify(args))
        let reportCopy = JSON.parse(args.report)
        let panelDefWithToolbar = { showtoolbar: panelJSON.pivot_definition.showtoolbar }
        reportCopy.dataSourceSettings.dataSource = queryName
        panelsDefinition.report = reportCopy
        panelDefWithToolbar.pivot = panelsDefinition
        const { data } = await client.mutate({
            mutation: updatePanelsDefinition,
            variables: {
                locale: "en",
                panels_definition: JSON.parse(JSON.stringify(panelDefWithToolbar)),
                pnaels_uid: panel.sys_panels_guid
            }
        })

        if (data?.insert_tasks_tasks?.returning) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    }


    function compareObjects(obj1, obj2) {
        // Compare columns
        if (JSON.stringify(obj1.columns) !== JSON.stringify(obj2.columns)) {
            return false;
        }

        // Compare rows
        if (JSON.stringify(obj1.rows) !== JSON.stringify(obj2.rows)) {
            return false;
        }

        // Compare filters
        if (JSON.stringify(obj1.filters) !== JSON.stringify(obj2.filters)) {
            return false;
        }

        // Objects are equal
        return true;
    }


    function saveReport(args) {
        if (!compareObjects(dataSourceSettings, JSON.parse(args.report).dataSourceSettings)) {
            saveToDB(args)
        }
    }
    function fetchReport(args) {
        let reportCollection = [];
        let reeportList = [];
        if (panelJSON.pivot_definition.pivot !== null) {
            reportCollection.push(panelJSON.pivot_definition.pivot)
        }
        reportCollection.map(function (item) { reeportList.push(item.reportName); });
        args.reportName = reeportList;
        args = panelJSON.pivot_definition
    }
    function loadReport(args) {
        setDataSourceSettings(JSON.parse(panelJSON.pivot_definition.pivot.report).dataSourceSettings)
        let reportCollection = [];
        pivotObj.dataSourceSettings = JSON.parse(panelJSON.pivot_definition.report).dataSourceSettings

        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportCollection = JSON.parse(localStorage.pivotviewReports);
        }
        reportCollection.map(function (item) {
            if (args.reportName === item.reportName) {
                args.report = item.report;
            }
        });
        if (args.report) {
            pivotObj.dataSourceSettings = JSON.parse(args.report).dataSourceSettings;
        }
    }
    function removeReport(args) {
        let reportCollection = [];
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportCollection = JSON.parse(localStorage.pivotviewReports);
        }
        for (let i = 0; i < reportCollection.length; i++) {
            if (reportCollection[i].reportName === args.reportName) {
                reportCollection.splice(i, 1);
            }
        }
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            localStorage.pivotviewReports = JSON.stringify(reportCollection);
        }
    }
    function renameReport(args) {
        let reportsCollection = [];
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportsCollection = JSON.parse(localStorage.pivotviewReports);
        }
        if (args.isReportExists) {
            for (let i = 0; i < reportsCollection.length; i++) {
                if (reportsCollection[i].reportName === args.rename) {
                    reportsCollection.splice(i, 1);
                }
            }
        }
        reportsCollection.map(function (item) {
            if (args.reportName === item.reportName) {
                item.reportName = args.rename;
            }
        });
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            localStorage.pivotviewReports = JSON.stringify(reportsCollection);
        }
    }
    function newReport(args) {
        console.log(args)
    }
    const beforeToolbarRender = (args) => {
        args.customToolbar.splice(6, 0, {
            type: 'Separator'
        });
        args.customToolbar.splice(9, 0, {
            type: 'Separator'
        });
    }

    if (!loaded) {
        return <div></div>;
    }


    return (
        <div ref={toastRef} className="pivots">
            <PivotViewComponent
                height={`${dynamicGridHeight * panel.panel_layout_json?.sizeY || 12}px `}
                width="100%"
                ref={pivotObj}
                dataSourceSettings={dataSourceSettings}
                showFieldList={panelJSON.pivot_definition.showtoolbar}
                gridSettings={{ columnWidth: 140 }}
                allowExcelExport={true}
                allowNumberFormatting={true}
                allowConditionalFormatting={true}
                allowPdfExport={true}
                showToolbar={true}
                allowCalculatedField={true}
                displayOption={displayOption}
                toolbar={panelJSON.pivot_definition.showtoolbar ? toolbarOptions : []}
                newReport={newReport}
                renameReport={renameReport}
                removeReport={removeReport}
                loadReport={loadReport}
                fetchReport={fetchReport}
                saveReport={saveReport}
                toolbarRender={beforeToolbarRender}
                chartSettings={chartSettings}
            >
                <Inject services={[FieldList, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting, NumberFormatting]} />

            </PivotViewComponent>
        </div>
    )
}

export default PivotToolbar



