import React, { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { gql, useMutation } from "@apollo/client";
import { downloadFileImage } from '../../apollo/mutations';
import noImage from '../../static/img/no-img.png';
import { SidebarContext } from './Main/context';
import moment from 'moment';

const CardsPanel = ({ panel, cardsQuery }) => {
    const client = useApolloClient();
    const [cards, setCards] = useState();
    const [animalImages, setAnimalImages] = useState({});
    const sidebarContext = useContext(SidebarContext);
    const [downloadFileImageMutation, { loading, error, data }] = useMutation(downloadFileImage);

    const _getDynamicScreen = async () => {
        const { data } = await client.query({ query: gql`${cardsQuery}` })
        const queryName = panelJSON.query_name
        setCards(data[queryName])
    }

    const getAnimalsImage = async (imgId) => {
        try {
            const { data } = await downloadFileImageMutation({ variables: { files_uid: imgId } });
            return data.fileDownload.file_content;
        } catch (error) {
            console.error('Error downloading file image', error);
            return null;
        }
    }

    useEffect(() => {
        const fetchAnimalImages = async () => {
            if (cards) {
                const imagePromises = cards.map(async (card) => {
                    const imageLocation = await getAnimalsImage(card?.[panelJSON.controls.find(obj => obj.column_order === 2).name]);
                    return {
                        id: card?.[panelJSON.controls.find(obj => obj.column_order === 2).name],
                        location: imageLocation
                    };
                });
                const images = await Promise.all(imagePromises);
                const animalImageObj = {};
                images.forEach((img) => {
                    animalImageObj[img.id] = img.location;
                });
                setAnimalImages(animalImageObj);
            }
        };
        fetchAnimalImages();
        _getDynamicScreen();
        if (panel?.panel_actions_json?.buttons) {
            _createTooblarMenu()
        }
    }, [cards, sidebarContext?.onSubmit]);


    function getMaxLabelLength(controls) {
        let maxLength = 0;
        for (let i = 2; i < controls.length; i++) {
            if (controls[i].label.length > maxLength) {
                maxLength = controls[i].label.length;

            }
        }
        return maxLength;
    }


    return (
        <div style={{ padding: '16px 16px 0', background: '#fff', overflow: 'auto', height: 'calc(100% - 41px)' }}>
            <div className="row">
                {
                    cards && cards.map((card, idx) => (
                        <div key={idx} className="col-lg-4 col-md-6 col-sm-12" style={{ padding: '0 8px', marginBottom: '16px', color: '#493838' }}>
                            <div style={{ minHeight: '180px', background: '#fff', border: '0.5px solid #E0E0E0' }}>
                                <h5 style={{ padding: '0px 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '14px', margin: 0, background: '#bbd2ec' }}>
                                    {card?.[panelJSON.controls.find(obj => obj.column_order === 1).name]}
                                    <div className="menu-container" style={{ width: '360px', display: 'flex', justifyContent: 'flex-end' }}>
                                    </div>
                                </h5>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%', padding: '0 32px' }}>
                                    {loading ? <p>Loading image...</p> : <img
                                        style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '5px', marginRight: '16px' }}
                                        alt='Image of pet'
                                        width='160px'
                                        height='150px'
                                        src={`data:image/jpeg;base64,${animalImages && animalImages[card?.[panelJSON.controls.find(obj => obj.column_order === 2).name]] || ''}`}
                                        onError={(e) => {
                                            e.target.onerror = null; // prevent infinite loop
                                            e.target.src = noImage;
                                        }}
                                    />}
                                    <div className='dogs_information' style={{ width: 'calc(100% - 171px)', fontSize: '12px' }}>
                                        {
                                            panelJSON.controls && panelJSON.controls.map((control, index) => (
                                                index > 1 ?
                                                    <div key={index} style={{ display: 'flex', flexWrap: 'nowrap', height: '24px', overflow: 'hidden' }}>
                                                        <div style={{ width: `${getMaxLabelLength(panelJSON.controls) * 6.1}px`, fontWeight: 'bold' }}>
                                                            {control.label}:
                                                        </div>
                                                        <div style={{ cursor: "pointer", minWidth: "100px", width: `calc(100% - ${getMaxLabelLength(panelJSON.controls) * 9.1 - 68}px)`, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", ":hover": { whiteSpace: "normal" }, }} title={card[control.name]}>{card[control.name]}</div>
                                                    </div> :
                                                    null
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )


}

export default CardsPanel