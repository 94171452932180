import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { gql } from "@apollo/client";
import _ from 'lodash';
import Map from './components/Map';
import New from './components/New';

const mapPanel = ({ panel, panelJSON, mapType }) => {
    const client = useApolloClient();
    const [animalsData, setAnimalsData] = useState(null);
    const [controls, setControls] = useState([]);

    useEffect(() => {
        const _getAnimals = async () => {
                let panelQuery = panelJSON.query
                const panelQueryReplaced = panelQuery.replace('@v_locale', '"en"').slice(1, -1)

                if (panelQueryReplaced) {
                    const { data } = await client.query({ query: gql(`${panelQueryReplaced}`) })
                    const controlsArray = panelJSON.controls
                    setControls(controlsArray)
                    setAnimalsData(data?.[panelJSON.query_name])
                }

        }
        _getAnimals()
    }, [])


    return (
        <div style={{ overflow: 'auto', height: 'calc(100% - 41px)' }}>
            {
                panelJSON.map_definition.sys_panels_maps_types_guid === "aa7c7be8-9c80-49d3-b779-ee9706c4ba30" ?
                    // <Map panel={panel} animalsData={animalsData} controls={controls} />
                    <New panel={panel} panelJSON={panelJSON} animalsData={animalsData} controls={controls} />
                    :
                    <div>
                    </div>
            }
        </div>

    )
}

export default mapPanel