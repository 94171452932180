import React, { useState, useEffect, useContext, memo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { GridComponent as GridComponentEJ2, ColumnsDirective, ColumnDirective, Page, Inject, Filter, Sort,InfiniteScroll  } from '@syncfusion/ej2-react-grids';
import { gql } from "@apollo/client";
import { SelectedRowContext } from '../../context/SelectedRowContext';
import { Tooltip } from '@syncfusion/ej2-react-popups';
import moment from 'moment'
import styled from 'styled-components';
import { GlobalTheme } from '../../context/GlobalTheme';
import { SidebarContext } from './Main/context';
import { getLocaleDateString } from '../../utils/getLocaleDateString';


function isValidDateFormat(dateString) {
    const parsedDate = Date.parse(dateString);
    return !isNaN(parsedDate);
}

const Wrapper = styled('div')`
  & #Grid_content_table {
    background: ${props => props.bg};
  }
  
  & td {
    color: ${props => props.textColor} !important;
  }
`

const GridComponent = styled(GridComponentEJ2)`
  & .e-content {
    background: ${props => props.bg} !important;
  }
`

const GridPanel = memo(({ panel, panelJSON, gridQuery, dynamicGridHeight }) => {
    const client = useApolloClient();
    const [gridList, setGridList] = useState('');
    const { selectedRow, changeSelectedRow } = useContext(SelectedRowContext);
    const sidebarContext = useContext(SidebarContext)

    useEffect(() => {
        const _fetchData = async () => {
            const { data } = await client.query({ query: gql`${gridQuery}` })
            const queryName = panelJSON.query_name
            const formattedList = data?.[queryName];
            setGridList(formattedList);
        }

        _fetchData()

        return() => {
            changeSelectedRow(null)
        }

    }, [panelJSON, sidebarContext?.onSubmit])


    function rowSelected(g) {
        changeSelectedRow(g.data)
    }

    const localDateFormat = getLocaleDateString()

    const changedGridList = gridList && gridList?.map(item => {
        const obj = { ...item };

        const tempObj = {}

        for (let key in obj) {
            if (typeof obj[key] === 'boolean') {
                // Handle boolean values
                tempObj[key] = obj[key] ? 'true' : 'false';
            } 
            else if (!!obj[key] && isNaN(obj[key]) && isValidDateFormat(obj[key]) && obj[key]?.trim().length === 19) {
                tempObj[key] = new Date(obj[key])
            } else if (!isNaN(obj[key]) && !!obj[key]) {
                tempObj[key] = Number(obj[key]).toLocaleString()
            }
        }

        return ({
            ...item,
            ...tempObj
        })
    })

    const globalTheme = useContext(GlobalTheme)

    return (
        <Wrapper
            className='control-section'
            bg={globalTheme[globalTheme.activeTheme]?.background}
            textColor={globalTheme[globalTheme.activeTheme]?.color}
            style={{ height: 'calc(100% - 49px)', width: '100%' }}
        >
            <GridComponent
                bg={globalTheme[globalTheme.activeTheme]?.background}
                id="Grid"
                height={'100%'}
                // dataSource={gridList}
                enableInfiniteScrolling={true}
                dataSource={changedGridList}
                allowFiltering={true}
                allowSorting={true}
                filterSettings={{
                    type: 'Excel',
                    showFilterBarStatus: true,
                }}
                rowSelected={rowSelected}
            >
                <ColumnsDirective>
                    {panelJSON && panelJSON.controls.map((control, index) => (
                        !control.hidden ?
                            <ColumnDirective
                                key={index}
                                field={control.name}
                                headerText={control.label}
                                clipMode='EllipsisWithTooltip'
                                width='150'
                            type={control.type === 'datetime' ? 'date' : control.type === 'number' ? 'number' : 'string'}
                            format={control.type === 'datetime' ? localDateFormat : undefined}
                            ></ColumnDirective> : null
                    ))}
                </ColumnsDirective>
                <Inject services={[Tooltip, Filter, Sort, InfiniteScroll]} />
            </GridComponent>
        </Wrapper>
    )


});

export default GridPanel


